import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"


const Page = () => (
  <Layout>
    <SEO title="Frequently Asked Questions (FAQ)"/>
    <main>
      <section id="about">
        <div className="container">
          <div className=" t2-padding">
            <h1>Frequently Asked Questions (FAQ)</h1>
          </div>
        </div>
      </section>

      <section className="overview" style={{ textAlign: "justify" }}>
        <div className="container">
          <h2>ABOUT HUMAINLY</h2>
          <h4 className="mt-3">What is Image Annotation?</h4>
          <p>Image annotation is a technique of labeling the image with certain outlines and keywords to make it
            recognizable for machines. The main purpose of image annotation is to use this annotated images for
            computer vision for machine learning or train the computer systems developed on AI-based technology.
          </p>
          <h4>What exactly is my job? </h4>
          <p>Your job will be to annotate images. For example, if your task will be to detect cats and dogs, you’ll
            manually draw bounding boxes around them and label each bounding box (BB) with a ‘dog’ or ‘cat’ label. To
            make it simpler and more interesting, we will show you annotation tools we are using and we will explain to
            you how to use them.
          </p>


          <h2 className="mt-5">BEFORE YOU START</h2>
          <h4 className="mt-3">What do I need to work with you?</h4>
          <p>All you need is to have access to a PC with Internet</p>
          <h4>Can I work from my phone?</h4>
          <p>No, the annotation platform/tools are not supported on phones</p>
          <h4>Do I need any kind of experience to work?</h4>
          <p>You only need very basic computing skills. We teach you how to use every annotation tool we work with,
            and we will provide you with extra training before each project (guidelines, tutorial videos, tests.)</p>

          <h2 className="mt-5">PAYMENTS</h2>
          <h4 className="mt-3">Will I get paid for every task I finish?</h4>
          <p>We send payments every two weeks. Annotators have to create their invoices every other Monday on our
            platform. We’ll help you with the numbers you need for this. We need from one to three working days to
            send the payments (time required to by BTC, for instance.)
          </p>
          <h4>Which payment methods do you support?</h4>
          <p>We mostly use BTC (Bitcoin) because it is easily exchangeable into any local currency and fees are low.
            If you don’t know much about Bitcoin, don’t worry! We are happy to let you know how it works and guide you
            through the exchanging process. Although, in some cases, we can also send the payment via Paypal or bank
            transfer.
          </p>
          <h4>How much do you pay per hour?</h4>
          <p>Our current rate is 1$/hr. We the pilots and test we estimate how much time you need to annotate one image
            and turn those numbers into a price per image/label. Also, we know annotators invest some extra time reading
            the guides and preparing themselves for the task, that’s why, at the end, we also add a percentage to
            compensate for this, making the final number around 1.33$/hr.
          </p>


          <h2 className="mt-5">WORK</h2>
          <h4 className="mt-3">How many hours is a full shift? Can I work part-time?</h4>
          <p>We are really flexible on this, the same as for the time you prefer to work. Ideally, we need people
            working 8 hours a day, 5 days a week. However, we are open to accept people working part-time, 3 hours a
            day, only weekends… what we need from you is to be responsible to complete your daily workload and to let
            us know in advance your availability.
          </p>
          <h4>You didn’t find answer to your question?</h4>
          <p>Please contact Luis on <a href="mailto:luis@humainly.com">luis@humainly.com</a>. He is our Image Annotation
            Expert and he will be happy to answer
            your additional questions. Your questions will also help us to improve this document!
          </p>
        </div>
      </section>
    </main>
  </Layout>
)

export default Page
